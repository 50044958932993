import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

function Square(props) {
    return (
        <button className="square" id = {props.number} onClick={props.onClick}>
            {props.value}
        </button>
    );
}

class Board extends React.Component {
    renderSquare(i) {
        return (
            <Square
                value={this.props.squares[i]}
                onClick={() => this.props.onClick(i)}
                number = {"sq"+i}
                key = {i}
            />
        );
    }

    render() {
        let table = [];
        for(let x = 0; x < 3; x++){
            let mini = [];
            for(let y = 0; y < 3; y++)
                mini.push(this.renderSquare((x*3)+y));
            table.push(<div className="board-row" key = {x}> {mini} </div>)
        }
        return (
            <div>
                {table}
            </div>
        );
    }
}

class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: [
                {
                    squares: Array(9).fill(null),
                    info: Array(9).fill(null),
                    usedPos: null
                }
            ],
            stepNumber: 0,
            xIsNext: true,
            width: 0,
            height: 0,
            onMobile: false,
            flipped: true
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }

    handleClick(i) {
        const history = this.state.history.slice(0, this.state.stepNumber + 1);
        const current = history[history.length - 1];
        const squares = current.squares.slice();
        if (calculateWinner(squares) || squares[i]) {
            return;
        }
        squares[i] = this.state.xIsNext ? "X" : "O";
        this.setState({
            history: history.concat([
                {
                    squares: squares,
                    info: "Player: " + squares[i] + " | Pos: (" + (Math.floor(i%3)+1) + ", " +(3-Math.floor(i/3)) + ")",
                    usedPos: i,
                }
            ]),
            stepNumber: history.length,
            xIsNext: !this.state.xIsNext
        });
        var win = calculateWinner(squares);
        if(win){
            for(let i = 0; i < 3; i++)
                document.getElementById("sq" + win[i]).style.background = "#ccc";
        }
        for (let i = 0; i < 9; i++) {
            document.getElementById("sq" + i).style.fontWeight = null;
        }
        document.getElementById("sq" + i).style.fontWeight = "bold";

    }

    jumpTo(step) {
        if(this.state.stepNumber !== step) {
            const history = this.state.history[step];
            for (let i = 0; i < 9; i++) {
                document.getElementById("sq" + i).style.background = null;
                document.getElementById("sq" + i).style.fontWeight = null;
            }
            this.setState({
                stepNumber: step,
                xIsNext: (step % 2) === 0
            });
            if(step !== 0)
                document.getElementById("sq" + history.usedPos).style.fontWeight = "bold";
            var win = calculateWinner(history.squares)
            if (win){
                for(let i = 0; i < 3; i++)
                    document.getElementById("sq" + win[i]).style.background = "#ccc";
            }
        }
    }

    render() {
        const history = this.state.history;
        const current = history[this.state.stepNumber];
        const winner = calculateWinner(current.squares);
        const moves = history.map((step, move) => {
            const desc = move ?
                'Go to move #' + move :
                'Go to game start';
            return (
                <li key={move}>
                    <button className="button" onClick={() => this.jumpTo(move)}>{desc}</button> {step.info}
                </li>
            );
        });

        let status;
        if (winner) {
            status = "Winner: " + current.squares[winner[0]];
        } else if (this.state.stepNumber === 9){
            status = "We Have A Tie!"
        }
        else {
            status = "Next player: " + (this.state.xIsNext ? "X" : "O");
        }

        return (
            <div className="game">
                <div className="status">{status}</div>
                <div className="game-board">
                    <Board
                        squares={current.squares}
                        onClick={i => this.handleClick(i)}
                    />
                </div>
                <div className="game-info">
                    <ol>{moves}</ol>
                </div>
                <button className="button" onClick={()=>{
                    this.setState({flipped: !this.state.flipped});
                    let element = document.getElementsByTagName("ol")[0];
                    if(this.state.flipped)
                        element.style.flexDirection = "column-reverse";
                    else
                        element.style.flexDirection = "column";
                }}>Reverse Order</button>
            </div>
        );
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let mobile = window.innerWidth < 500;
        this.setState({ width: window.innerWidth, height: window.innerHeight, onMobile: mobile });
    }
}

// ========================================

ReactDOM.render(<Game />, document.getElementById("root"));

function calculateWinner(squares) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6]
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return [a, b, c];
        }
    }
    return null;
}

function log(text){
    document.getElementById("log").innerText += "\n" + text;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


